import styled from "styled-components";

export const Container = styled.div`
margin-top: 50px;
margin-bottom: 50px;

`

export const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    

    .titulo {
        font-family: "Dolce Vita";
        font-size: 2rem;
    }

    .mensagem {
        margin-top: 10px;
        color: red;
    }
    
    .importante {
        background: #ff000050;
        margin-bottom: 30px;
        border-bottom: solid 5px red;   
    }

    input, textarea {
        padding: 7px;
        margin-bottom: 5px;
    }

`

export const ImageUploadContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    .titulo {
        font-family: "Dolce Vita";
        font-size: 2rem;
    }

    progress {
        margin-top: 10px;
    }

    input, textarea {
        padding: 7px;
        margin-bottom: 5px;
    }

    
    .thumb {
        :first-child {
            img {    
                border: solid 5px green;
            }
        }
        img {
            width: 100%;
            max-height: 150px;
            object-fit: cover;
            margin: 10px;
        }
    }
`

export const MessageContainer = styled.div`
    .custom-row {
        &.first{
            background-color: #77777750;
        }
        &.second {
            background-color: #ffffff50;
        }
    }
`