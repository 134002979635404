import React from 'react'
import { Container } from './style'

export default function topo(props) {
    return (
        <Container>
            {props.title &&
                <h1>
                    {props.title}
                </h1>
            }
        </Container>
    )
}
