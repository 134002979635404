import React from 'react'
import Banner from '../components/banner/banner'

export default function index() {
    return (
        <div>
            <Banner />
        </div>
    )
}
