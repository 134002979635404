import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

import Header from './components/header/header'
import Footer from './components/footer/footer'

import Index from './pages/index'
import QuemSomos from './pages/quem-somos'
import Projetos from './pages/projetos'
import ProjetosInterna from './pages/projetos-interna'
import Contato from './pages/contato'

import Admin from './pages/admin'
import AdminMessages from './pages/admin-mensagens';

import "./global.css"


function App(props) {
  return (
    <BrowserRouter>
      <Header {...props} />
      <Switch>
        <Route exact path='/' component={Index} />
        <Route exact path='/quem-somos' component={QuemSomos} />
        <Route exact path='/projetos' component={Projetos} />
        <Route exact path='/projetos/:projeto/' component={ProjetosInterna} />
        <Route exact path='/contato' component={Contato} />

        <Route exact path='/admin' component={Admin} />
        <Route path='/admin/mensagens' component={AdminMessages} />

        <Redirect from='*' to='/' />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
