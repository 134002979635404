import React, { useState } from 'react'
import firebase from '../../firebase'

import { FormContainer, ImageUploadContainer } from './style'

export default function Form() {

    const [image, setImage] = useState(null);
    const [url, setUrl] = useState('');
    const [progress, setProgress] = useState(0);
    const [uploadedImages, setUploadedImages] = useState(Array);
    const [uploadedImagesCount, setUploadedImagesCount] = useState(0);


    const [linkProjeto, setLinkProjeto] = useState('');
    const [titulo, setTitulo] = useState('');
    const [descricao, setDescricao] = useState('');
    const [categoria, setCategoria] = useState('');
    const [inicio, setInicio] = useState('');
    const [conclusao, setConclusao] = useState('');
    const [localizacao, setLocalizacao] = useState('');
    const [area, setArea] = useState('');

    function handleChange(e) {
        if (e.target.files[0]) {
            const image = e.target.files[0];
            setImage(image);
        }
    }
    function resetState() {
        setImage(null);
        setUrl('');
        setProgress(0);
        setUploadedImages(Array);
        setUploadedImagesCount(0);
        setLinkProjeto('');
        setTitulo('');
        setDescricao('');
        setCategoria('');
        setInicio('');
        setConclusao('');
        setLocalizacao('');
        setArea('');
    }

    // function linkParse(string) {
    //     const link = string;
    //     link = link.toLowerCase();
    //     link = link.replace(/[^a-zA-Z0-9 ]+/g, "");
    //     link = link.replace(/\s+/g, "-");
    //     return link;
    // }

    async function handleSave(e) {
        e.preventDefault();

        const link = linkProjeto.toLowerCase().replace(/[^a-zA-Z0-9 ]+/g, "").replace(/\s+/g, "-");

        if (link !== '' && titulo !== '') {
            await firebase.db.collection("projetos").doc(link).set({
                link: link,
                titulo: titulo,
                descricao: descricao,
                categoria: categoria,
                inicio: inicio,
                conclusao: conclusao,
                localizacao: localizacao,
                area: area,
                imagens: uploadedImages,
                createdAt: new Date().getTime(),
            }).then(
                alert("Projeto postado com sucesso!")
            ).then(
                resetState()
            )
        }
    }

    function handleUpload() {
        if (image != null) {

            const uploadTask = firebase.storage.ref(`images/${image.name}`).put(image);
            uploadTask.on('state_changed',
                (snapshot) => {
                    // progress function ....
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setProgress(progress);
                },
                (error) => {
                    // error function
                    console.log(error);
                },
                () => {
                    // complete function ....
                    firebase.storage.ref('images').child(image.name).getDownloadURL().then(url => {
                        setUrl(url);
                        setUploadedImages([...uploadedImages, url]);
                        setUploadedImagesCount(uploadedImagesCount + 1);
                    })
                });
        } else {
            alert('Você não anexou uma imagem!')
        }
    }


    return (
        <div className="row">
            <div className="col-12 col-md-6">
                <FormContainer>
                    <div className="titulo">Publicar projeto</div>
                    <span class="mensagem">NÃO TEM COMO MUDAR DEPOIS DE PUBLICADO!</span>
                    <input class="importante" placeholder="Link" required onChange={e => setLinkProjeto(e.target.value)} value={linkProjeto} />
                    <input placeholder="Titulo do projeto" required onChange={e => setTitulo(e.target.value)} value={titulo} />
                    {/* <textarea placeholder="Descrição do projeto" rows="5" required onChange={e => setDescricao(e.target.value)} value={descricao} /> */}
                    <input placeholder="Categoria" onChange={e => setCategoria(e.target.value)} value={categoria} />
                    {/* <input placeholder="Data do inicio" onChange={e => setInicio(e.target.value)} value={inicio} /> */}
                    <input placeholder="Ano" onChange={e => setConclusao(e.target.value)} value={conclusao} />
                    {/* <input placeholder="Localização" onChange={e => setLocalizacao(e.target.value)} value={localizacao} /> */}
                    {/* <input placeholder="Área" onChange={e => setArea(e.target.value)} value={area} /> */}
                    <hr />
                    <div className="titulo">{uploadedImagesCount} imagens foram adicionadas!</div>
                    <input placeholder="Imagens" hidden defaultValue={uploadedImages} />
                    <button onClick={(e) => handleSave(e)}>Publicar</button>
                </FormContainer>
            </div>
            <div className="col-12 col-md-6">
                <ImageUploadContainer>
                    <div className="titulo">Upload de imagem</div>
                    <progress value={progress} max="100" />
                    <br />
                    <input type="file" onChange={(e) => handleChange(e)} />
                    <button onClick={() => handleUpload()}>Upload</button>
                    <br />
                    <div className="quantidade">
                        Você fez upload de {uploadedImagesCount} imagens.
                    </div>
                    <div className="row">
                        {uploadedImages.map(uploaded => (
                            <div className="col-4 thumb">
                                <img src={uploaded} alt="Uploaded images" height="150" />
                            </div>
                        ))}
                    </div>
                </ImageUploadContainer>
            </div>
        </div>
    )
}
