import React, { useState } from 'react'
import firebase from '../../firebase'
// import { useAuthState } from 'react-firebase-hooks/auth';

import { Container, Wrapper } from './styles'

function Login(props) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    // const [user] = useAuthState(firebase.auth);

    const [error, setError] = useState(false)

    function handleSubmit(e) {
        e.preventDefault()
        firebase.login(email, password).then(() => {
            console.log("OK")
        }).catch((err) => {
            setError(err.message)
        })
    }

    return (
        <Container>
            <Wrapper>
                <h1>Área restrita</h1>
                <form onSubmit={handleSubmit}>
                    <input placeholder='Email' type="email" required autoFocus
                        name='email' onChange={e => setEmail(e.target.value)} />
                    <input placeholder='Senha' type="password" required
                        name='password' onChange={e => setPassword(e.target.value)} />
                    {error && <div>{error}</div>}
                    <button>LOGIN</button>
                </form>
            </Wrapper>
        </Container>
    )
}

export default Login
