import React, { useEffect, useState } from 'react';
import firebase from '../../firebase'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useAuthState } from 'react-firebase-hooks/auth';

import { Container } from './style';

export default function ProjetoCard(props) {

    const [projeto, setProjeto] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [confirmation, setConfirmation] = useState(false);

    const [user] = useAuthState(firebase.auth);


    useEffect(() => {
        var projetos = firebase.db.collection("projetos").doc(props.match.params.projeto);
        projetos.get().then(function (p) {
            if (p.exists) {
                setProjeto(p.data())
            } else {
                alert('Projeto não existe')
                props.history.push('/')
            }
        }).catch(function (error) {
            console.log("Error getting document:", error);
        });
    }, [props.match.params.projeto, props.history])


    function deleteLink(id) {
        firebase.db.collection("projetos").doc(id).delete()
    }


    return (

        <Container className="container">
            <div className="row">
                {projeto !== undefined &&
                    <>
                        <div className="col-12 col-md-3 descricao-container">
                            <h1>{projeto.titulo}</h1>
                            <div className="descricao">
                                {projeto.descricao} <br />
                                {projeto.categoria !== undefined &&
                                    (<span>Categoría: {projeto.categoria} <br /> </span>)
                                }
                                {projeto.conclusao !== undefined &&
                                    (<span>Ano: {projeto.conclusao} <br /> </span>)
                                }
                                {/* {projeto.inicio !== undefined &&
                                    (<span>Data inicio: {projeto.inicio} <br /> </span>)
                                }
                                {projeto.area !== undefined &&
                                    (<span>Area: {projeto.area} <br /> </span>)
                                }
                                {projeto.area !== undefined &&
                                    (<span>Localização: {projeto.localizacao} <br /> </span>)
                                } */}
                            </div>
                        </div>
                        <div className="col-12 col-md-9 galeria-container">
                            <div className="row">
                                {projeto.imagens.map(image => (
                                    <div className="col-12 col-md-6 col-xl-4 image-container" onClick={() => setIsOpen(true)}>
                                        <img src={image} alt="" />
                                    </div>
                                ))}
                            </div>
                        </div>

                        {isOpen && (
                            <Lightbox
                                mainSrc={projeto.imagens[photoIndex]}
                                nextSrc={projeto.imagens[(photoIndex + 1) % projeto.imagens.length]}
                                prevSrc={projeto.imagens[(photoIndex + projeto.imagens.length - 1) % projeto.imagens.length]}
                                onCloseRequest={() => setIsOpen(false)}
                                onMovePrevRequest={() =>
                                    setPhotoIndex(
                                        (photoIndex + projeto.imagens.length - 1) % projeto.imagens.length
                                    )
                                }
                                onMoveNextRequest={() =>
                                    setPhotoIndex(
                                        (photoIndex + 1) % projeto.imagens.length
                                    )
                                }
                            />
                        )}
                    </>
                }
            </div>
            {user && <div className="delete-button" onClick={() => setConfirmation(!confirmation)}>Deletar projeto</div>}
            {confirmation && user && <div className="confirmation-button" onClick={() => deleteLink(props.match.params.projeto)}>SIM!</div>}
        </Container>
    );
}
