import styled from "styled-components";

import logoBg from '../../assets/logo-bg.png'

export const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    transition: 0.5s;
    font-family: 'Dolce Vita';
    background-color: #fff;
    z-index: 99;
    border-bottom: solid 2px transparent;
    
    :hover {
        border-bottom: solid 2px #008884;
        .menu-container {
            a {
                .menu-button {
                
                    
                }
            }
        }
    }

    .mobile-row {
        opacity: 0;
        height: 0;
        transition: all, 0.6s;
        &.active {
            opacity: 1;
            height: 100%;
        }
    }

    .custom-row {
        display: flex;
        align-items: center;
        height: 100px;

        @media screen and (max-width: 767px) {
          height: 100px;  
        }
        
        .menu-container {
            display: flex;
            
            a {
                &.active {
                        .menu-button {
                            border-bottom: solid 1.5px #008884;
                            color: #000;
                        }
                }
            }

            @media screen and (max-width: 767px) {
                display: none;
                
            }

            .menu-button {
                font-size: 1.15rem;
                margin: 0 20px;
                text-transform: uppercase;
                text-shadow: 1px 1px 2px rgba(56,56,56,0.4);
                color: #565656;
                text-decoration: none;
                transition: all 0.3s;
                white-space: nowrap;
                border-bottom: solid 1.5px transparent;
            
                :hover {
                    
                    color: #008884;
                }
            }

        }

        .logo-container {
            position: relative;
            img.logo {
                max-width: 380px;
                width: 100%;
                max-height: 80px;
                object-fit: contain;
                @media screen and (max-width: 767px) {
                    max-width: 100%;
                }
            }

            img.detail {
                position: absolute;
                top: -35px;
                left: 135px;
                z-index: -1;
                width: 205px;
                height: 200px;
                object-fit: contain;
                @media screen and (max-width: 1490px) {
                    display: none;
                }
            }

            .button {
                height: 40px;
                position: absolute;
                top: calc(50% - 20px);
                right: 10px;
                display: none;
                font-size: 2rem;
                color: #565656;
                cursor: pointer;

                @media screen and (max-width: 767px) {
                    display: initial;
                }
            }
        }
    }

    .menu-mobile {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        
        /* background-image: url(${ logoBg});
        background-position: center;
        background-size:cover; */
        
        ::after {
           position: absolute;
           width: 100%;
           height: 100%;
           top: 0;
           left: 0;
           content: '';     
           background-image: url(${ logoBg});
           background-position: center;
           background-size:cover;
           background-repeat: no-repeat;
           opacity: 0.1;
           z-index: -1;
        }

        .menu-button {
            color: #565656;
            padding: 10px;
            font-size: 1.2rem;
        }

        .titulo {
            text-align: center;
        }

        .social-buttons {
            font-size: 3.5rem;
            font-family: "Silver Charm";
            color: #008884;
            font-weight: 300;
            text-shadow: -1px -1px 0px #565656, 1px 1px 0px #565656, -1px 1px 0px #565656, 1px -1px 0px #565656, 1px -1px 0px #565656;

            .social-wrapper {
                display: flex;

                .button {
                    margin: 10px;
                    text-shadow: none;
                    a {
                        font-size: 2rem;
                        color: #008884;
                       
                    }
                }
            }
        }

    }
`;