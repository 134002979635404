import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    img {
        width: 100%;
        height: 100vh;
        object-fit: cover;
    }
`;