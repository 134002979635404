import React from 'react'
import Topo from '../components/tituloTopo/topo'
import Contact from '../components/contato/contact'

export default function Contato() {

    return (
        <div>
            <Topo />
            <Contact />
        </div>
    )
}
