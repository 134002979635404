import React from 'react';
import firebase from '../firebase'
import { useAuthState } from 'react-firebase-hooks/auth';

import Topo from '../components/tituloTopo/topo'
import Login from '../components/login/Login'

import Application from '../components/application/Application'

export default function Admin(props) {

    const [user] = useAuthState(firebase.auth);

    return (
        <div>
            <Topo />
            {user === null ? <Login /> : <Application />}
        </div>
    );
}
