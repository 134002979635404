import styled from "styled-components";



export const Container = styled.div`

    .custom-row {
        margin-bottom: 40px;
    }

`

export const CardContainer = styled.div`
        position: relative;
        padding: 0;
        height: 250px;

        :hover {
            .mask {
                opacity: 1;
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .mask {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0, .5);
            transition: 0.2s;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .titulo {
                color: #fff;
                font-family: "Dolce Vita";
                font-size: 1.2rem;
            }
            .categoria {
                color: #fff;
                font-family: "Dolce Vita";
                font-size: .8rem;
                letter-spacing: 2px;
            }
        }

    
`