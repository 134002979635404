import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/firebase-firestore'
import 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyAyUc2MNqxKp8Xwg3e6ZDb0c0p4GcYFhXk",
    authDomain: "studio-dous.firebaseapp.com",
    databaseURL: "https://studio-dous.firebaseio.com",
    projectId: "studio-dous",
    storageBucket: "studio-dous.appspot.com",
    messagingSenderId: "584037585871",
    appId: "1:584037585871:web:c6b5c69f6c17b7913a38ba",
    measurementId: "G-N8VNHNVNP3"
};
// Initialize Firebase
// firebase.initializeApp(firebaseConfig);
// firebase.analytics();


class Firebase {
    constructor() {
        app.initializeApp(firebaseConfig)
        this.auth = app.auth()
        this.db = app.firestore()
        this.storage = app.storage()
    }

    login(email, password) {
        return this.auth.signInWithEmailAndPassword(email, password)
    }

    logout() {
        return this.auth.signOut()
    }

    // async register(firstName, lastName, email, password) {
    //     await this.auth.createUserWithEmailAndPassword(email, password)
    //     return this.auth.currentUser.updateProfile({
    //         displayName: `${firstName} ${lastName}`
    //     }).then(() =>
    //         this.db.collection('users').doc(this.auth.currentUser.uid).set({
    //             firstName: firstName,
    //             lastName: lastName,
    //             uid: this.auth.currentUser.uid,
    //             pass: firstName + lastName
    //         })
    //     )
    // }

}

export default new Firebase()