import React from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import firebase from '../../firebase'
import { useCollection } from 'react-firebase-hooks/firestore';


import { Container } from './style'

export default function Banner() {
    var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        autoplaySpeed: 5000,
        autoplay: true,
        fade: true,
        easing: 'linear',
        speed: 1500,
        pauseOnHover: false,
    };

    const [list, loading, error] = useCollection(
        firebase.db.collection('projetos').orderBy('createdAt', 'desc').limit(3),
        { snapshotListenOptions: { includeMetadataChanges: true }, }
    );

    return (
        <Container>
            <Slider {...settings}>


                {list &&
                    list.docs.map(doc => (
                        <img src={doc.data().imagens[0]} alt={doc.data().titulo}></img>
                    ))
                }


            </Slider>
        </Container>
    )
}
