import React from 'react'
import Galeria from '../components/galeria/galeria'
import Topo from '../components/tituloTopo/topo'

export default function projetos() {
    return (
        <div>
            <Topo />
            <Galeria />
        </div>
    )
}
