import React from 'react'
import { Container } from '../sobreCard/style'

export default function sobreCard(props) {
    return (
        <Container className="container">
            {props.reverse === true ? (
                <div className="row">
                    <div className="col-12 offset-xl-3 col-xl-3 name-container">
                        <h2>
                            {props.nome}
                        </h2>
                        <h3>{props.titulo}</h3>
                    </div>
                    <div className="col-12 col-xl-6 about-container">
                        <div className="inner-container">
                            <span>{props.descricaoTitulo}</span>
                            {props.descricao}
                        </div>
                    </div>
                    <div className="image-container" style={{ left: 0 }}>
                        <img src={props.imagem} alt="Placeholder" />
                    </div>
                </div>
            ) : (
                    <div className="row">
                        <div className="col-12 col-xl-3 name-container">
                            <h2>
                                {props.nome}
                            </h2>
                            <h3>{props.titulo}</h3>
                        </div>
                        <div className="col-12 col-xl-6 about-container">
                            <div className="inner-container">
                                <span>{props.descricaoTitulo}</span>
                                {props.descricao}
                            </div>
                        </div>
                        <div className="image-container">
                            <img src={props.imagem} alt="Placeholder" />
                        </div>
                    </div>
                )
            }

        </Container>
    )
}
