import React from 'react'

import { Container } from './style'
import Form from './Form'
import { Link } from 'react-router-dom'

export default function Application() {


    return (
        <>
            <div className="container">
                <div className="row">
                    <Link to='/admin/' style={{ marginRight: 20 }}>Publicar projeto</Link>
                    <Link to='/admin/mensagens'>Vizualizar mensagens</Link>
                </div>
            </div>
            <Container className="container">
                <Form />
            </Container>
        </>
    )
}
