import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 0 15px;

    text-transform: uppercase;
    color: #565656;
    font-size: 14px;
    width: 100%;
    height: 40px;
    position: fixed;
    bottom: 0;

    .copyright {
        font-family: "Dolce Vita";
        font-weight: 600;
        span {
            @media screen and (max-width: 500px) {
                display: none;
            }
        }
    }

    .social-container {
        display: flex;
        .social-button {
            margin: 0 5px;
            font-size: 1.5rem;
            a {
                i {
                    transition: 0.5s;
                    color: #565656;
                    :hover {
                        color: #008884;
                    }
                }
            }
        }
    }
`;