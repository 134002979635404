import React from 'react'
import { Container } from './style'

export default function footer() {
    return (
        <Container>
            <div className="copyright">
                Studio Dous @ <span>Todos direitos reservados.</span>
            </div>
            <div className="social-container">
                <div className="social-button">
                    <a href="https://www.instagram.com/studiodous/" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-instagram"></i>
                    </a>
                </div>
                <div className="social-button">
                    <a href="https://br.pinterest.com/studiodousarquitetura/" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-pinterest"></i>
                    </a>
                </div>
                {/* <div className="social-button">
                    <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-linkedin-in"></i>
                    </a>
                </div> */}
            </div>
        </Container>
    )
}
