import React from 'react'
import Topo from '../components/tituloTopo/topo'
import SobreCard from '../components/sobreCard/sobreCard'

export default function quemSomos() {
    return (
        <div>
            <Topo title="Quem somos" />
            <SobreCard
                nome="Ana fantasia"
                titulo="Arquiteta e Urbanista"
                // descricaoTitulo="Aqui vem uma parte chamativa se quiser"
                descricao="Ana Carolina Fantasia, Arquiteta e Urbanista, possui um enorme fascínio por arquitetura, viagens e gastronomia. Apaixonada por empreendedorismo, preza pela criação de espaços que proporcionem experiências únicas quanto a vivência do usuário, imprimindo a característica da marca ou do morador, ao ambiente projetado. "
                imagem={`https://firebasestorage.googleapis.com/v0/b/studio-dous.appspot.com/o/uploads%2FAna-Fantasia.jpeg?alt=media&token=885ebaf5-b70c-4fd6-a596-253e51dc2241`}
            />
            <SobreCard
                nome="Leticia de Paula"
                titulo="Arquiteta e Urbanista"
                // descricaoTitulo="Aqui vem uma parte chamativa se quiser"
                descricao="Letícia de Paula Costa, formada em Arquitetura e Urbanismo, traz em si uma paixão por arquitetura, artes, música e viagens, englobando todas as experiências que estas lhe proporcionam e que de alguma forma possam ser implantadas em seu processo de criação projetual."
                imagem={`https://firebasestorage.googleapis.com/v0/b/studio-dous.appspot.com/o/uploads%2FLeticia-de-Paula.jpeg?alt=media&token=932944a1-2294-475e-a9e0-d7c27ff584b7`}
                reverse={true}
            />
        </div>
    )
}
