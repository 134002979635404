import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    min-height: 650px;

    .name-container {
        background-color: #fff;
        padding: 20px;
        h2 {
            text-transform: uppercase;
            color: #008884;
            line-height: 30px;
        }
        h3 {
            font-size: .8rem;
        }
    }
    .about-container {
        background-color: #fff;
        .inner-container {
            display: flex;
            flex-direction: column;
            padding: 20px;
            span {
                font-weight: 500;
            }
        }
    }
    .image-container {
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        img {
            max-width: 420px;
            max-height: 460px
        }
        @media screen and (max-width: 1199px) {
            position: initial;
            width: 100%;
            margin-bottom: 100px;
        }
        @media screen and (max-width: 767px) {
            img {
                width: 100%;
                object-fit: contain;

            }
        }
    }
`