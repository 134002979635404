import styled from "styled-components";

export const Container = styled.div`


    .adress-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 30px 0;
        color: #565656;
        letter-spacing: 0.8px;
        text-align: center;
    }

    .whatsapp-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 30px 0;
        color: #565656;
        letter-spacing: 0.8px;   

        .button-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            a {
                color: #565656;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: 0.2s;
                &.first {
                    margin-right: 30px;
                    @media screen and (max-width: 407px) {
                        margin-right: 0px;
                        margin-bottom:20px;
                    }
                }
                &:hover {
                    color: #008884;
                }
                i {
                    font-size: 40px;
                    margin-right: 7px;
                }
                .wrapper {

                    .name {
                        line-height: 1rem;
                        font-weight: 600;
                    }
                    .number {

                    }
                }
            }
        }
    }

    .map-container {
        iframe {
            -webkit-filter: grayscale(100%);
       -moz-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
         -o-filter: grayscale(100%);
            filter: grayscale(100%);
            width: 100%;
        }
    }

    .contact-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        

        .mail-button {
            width: 100%;
            display: flex;
            justify-content: center;
        
            .button {
                padding: 10px 30px;
                color: #565656;
                border: solid 2px #565656;
                border-radius: 99px;
                margin-bottom: 10px;
                i {
                margin-right: 10px;
                }
            }
        }

        form {
            margin: 10px;

            input, textarea {
                width: 100%;
                margin: 4px 0;
                padding: 10px;
                border: 0;
                border-bottom: solid 2px #565656;
                outline: none;

                :focus {
                    border: 0;
                    border-bottom: solid 2px #008884;
                }

               

            }


            button {
                background: #008884;
                padding: 10px 30px;
                color: #fff;
                border: solid 2px transparent;
                border-radius: 99px;
                text-transform: uppercase;
                margin: 10px;
                float: right;
                outline: none;
                @media screen and (max-width: 767px) {
                    margin-bottom: 60px;
                }
            }

        }

    }
    
`;