import styled from "styled-components";

export const Container = styled.div`
    padding-top: 60px;
    padding-bottom: 60px;
    .descricao-container {

        h1 {
            font-family: "Dolce Vita";
            
        }

    }

    .galeria-container {
        cursor: pointer;

        .image-container {

            img {
                width: 100%;
                object-fit: cover;
                margin-bottom: 30px;
            }
        }
    }

    .delete-button {
        display: flex;
        background-color:red;
        float: right;
        color: #fff;
        padding: 10px;
        border-radius: 99px;
        cursor: pointer;
    }
    .confirmation-button {
        display: flex;
        background-color:red;
        float: right;
        color: #fff;
        padding: 10px;
        border-radius: 99px;
        margin-right: 20px;
        cursor: pointer;
    }
`