import React from 'react'
import firebase from '../../firebase'
import { useCollection } from 'react-firebase-hooks/firestore';

import { Container } from './style';

import CardContainer from './project';

export default function Galeria(props) {



    const [list, loading, error] = useCollection(
        firebase.db.collection('projetos').orderBy('createdAt', 'desc'),
        { snapshotListenOptions: { includeMetadataChanges: true }, }
    );


    return (
        <Container className="container-fluid">
            <div className="row custom-row">
                {error && <strong>Error: {JSON.stringify(error)}</strong>}
                {loading && <div></div>}
                {list && (
                    <>
                        {list.docs.map(doc => (
                            <CardContainer key={doc.id}
                                link={doc.data().link}
                                titulo={doc.data().titulo}
                                descricao={doc.data().descricao}
                                imagens={doc.data().imagens}
                                localizacao={doc.data().localizacao}
                                inicio={doc.data().inicio}
                                conclusao={doc.data().conclusao}
                                categoria={doc.data().categoria}
                                area={doc.data().area}
                                createdAt={doc.data().createdAt}
                            // delete={() => deleteLink(doc.id)}
                            />))}
                    </>
                )}
            </div>

        </Container>
    )
}
