import React from 'react'
import { MessageContainer } from './style'

import firebase from '../../firebase'
import { useCollection } from 'react-firebase-hooks/firestore';
import { Link } from 'react-router-dom';

export default function Messages(props) {

    const [list, loading, error] = useCollection(
        firebase.db.collection('contatos').orderBy('createdAt', 'desc'),
        { snapshotListenOptions: { includeMetadataChanges: true }, }
    );


    return (
        <MessageContainer className="container">

            <div className="row">
                <Link to='/admin/' style={{ marginRight: 20 }}>Publicar projeto</Link>
                <Link to='/admin/mensagens'>Vizualizar mensagens</Link>
            </div>

            <div className="row">
                <div className="col-12">
                    {error && <strong>Error: {JSON.stringify(error)}</strong>}
                    {loading && <div>Carregando mensagens</div>}
                    {list && (
                        <>
                            {list.docs.map((doc, key) => (
                                (key % 2) !== 0 ? (
                                    <div className="row custom-row first">
                                        <div className="col-12 col-md-1">
                                            {doc.data().name}
                                        </div>
                                        <div className="col-12 col-md-2">
                                            {doc.data().phone}
                                        </div>
                                        <div className="col-12 col-md-3">
                                            {doc.data().email}
                                        </div>
                                        <div className="col-12 col-md-4">
                                            {doc.data().message}
                                        </div>
                                        <div className="col-12 col-md-2">
                                            {doc.data().createdAt}
                                        </div>
                                    </div>
                                ) : (
                                        <div className="row custom-row second">
                                            <div className="col-12 col-md-1">
                                                {doc.data().name}
                                            </div>
                                            <div className="col-12 col-md-2">
                                                {doc.data().phone}
                                            </div>
                                            <div className="col-12 col-md-3">
                                                {doc.data().email}
                                            </div>
                                            <div className="col-12 col-md-4">
                                                {doc.data().message}
                                            </div>
                                            <div className="col-12 col-md-2">
                                                {doc.data().createdAt}
                                            </div>
                                        </div>
                                    )
                            ))}
                        </>
                    )}
                </div>
            </div>
        </MessageContainer>
    )
}
