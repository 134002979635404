import React from 'react'

import { Container } from './style';

export default function Modal(props) {
    return (
        <Container onClick={props.fechar}>
            <div className="inner-container">
                {props.text}
            </div>
        </Container>
    )
}
