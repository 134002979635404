import styled from "styled-components";

export const Container = styled.div`
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Dolce Vita';

    h1 {
        font-size: 2.5rem;
        color: #008884;
        letter-spacing: 4px;
        text-transform: uppercase;
        font-weight: 600;
        margin: 60px 0;
        @media screen and (max-width: 354px) {
            font-size: 2rem;
        }
    }
`