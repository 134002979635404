import React, { useState } from 'react';
import firebase from '../../firebase'

import { Container } from './style';
import Modal from '../modal/modal';

export default function Contato() {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [modal, setModal] = useState(false);

    function resetState() {
        setName('');
        setEmail('');
        setPhone('');
        setMessage('');
    }

    async function handleSave(e) {
        e.preventDefault();


        if (name !== '' && email !== '' && phone !== '') {
            await firebase.db.collection("contatos").doc().set({
                name: name,
                email: email,
                message: message,
                phone: phone,
                createdAt: new Date().getTime(),
            }).then(
                setModal(true)
            ).then(
                resetState()
            )
        }
    }


    return (
        <Container className="container">
            {modal && <Modal text="Formulario enviado com sucesso!" fechar={() => setModal(false)} />}
            <div className="row">
                <div className="col-12 adress-container">
                    Rua 239, n 77, Setor Coimbra, Goiânia – GO, CEP 74535-370
                </div>
                <div className="col-12 whatsapp-container">
                    <div className="button-wrapper">
                        <a className="first" href="https://api.whatsapp.com/send?phone=5562981351298" target="_blank" rel="noopener noreferrer">
                            <i class="fab fa-whatsapp"></i>
                            <div className="wrapper">
                                <div className="name">Ana Fantasia</div>
                                <div className="number">(62) 98135-1298</div>
                            </div>
                        </a>
                        <a href="https://api.whatsapp.com/send?phone=5562981890108" target="_blank" rel="noopener noreferrer">
                            <i class="fab fa-whatsapp"></i>
                            <div className="wrapper">
                                <div className="name">Letícia de Paula</div>
                                <div className="number">(62) 98189-0108</div>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="col-12 col-md-6 map-container">
                    <iframe title="Mapa" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1351.2431433759418!2d-49.2791331705544!3d-16.682249070376713!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf39e313e936d6b8f!2sStudio%20Dous%20Arquitetura%20e%20Interiores!5e0!3m2!1spt-BR!2sbr!4v1581472573965!5m2!1spt-BR!2sbr" width="600" height="450" frameborder="0" style={{ border: 0 }} allowfullscreen=""></iframe>
                </div>
                <div className="col-12 col-md-6 contact-container">


                    <div className="mail-button">
                        <a href="mailto:contato@studiodous.com.br" target="_blank" rel="noopener noreferrer">
                            <div className="button">
                                <i class="far fa-envelope"></i><span>contato@studiodous.com.br</span>
                            </div>
                        </a>
                    </div>

                    <form>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <input type="text" placeholder="Nome" onChange={e => setName(e.target.value)} value={name} required />
                            </div>
                            <div className="col-12 col-md-6">
                                <input type="tel" placeholder="Telefone" onChange={e => setPhone(e.target.value)} value={phone} required />
                            </div>
                            <div className="col-12">
                                <input type="email" placeholder="E-mail" onChange={e => setEmail(e.target.value)} value={email} required />
                            </div>
                            <div className="col-12">
                                <textarea placeholder="Mensagem" onChange={e => setMessage(e.target.value)} value={message} />
                            </div>
                            <button onClick={(e) => handleSave(e)}>Enviar mensagem <i class="far fa-paper-plane"></i></button>
                        </div>
                    </form>
                </div>
            </div>

        </Container>
    );
}
