import React, { useState } from 'react'
import { Container } from './style'
import { Link, NavLink } from 'react-router-dom'

import logo from '../../assets/logo.png'
import detail from '../../assets/detail.png'

export default function Header(props) {

    const [menuMobileOpen, setMenuMobileOpen] = useState(false);

    return (
        <Container className="container-fluid">
            <div className="row custom-row">
                <div className="col-12 col-md-9 col-xl-9 menu-container">
                    <NavLink to='/' exact>
                        <div className="menu-button">
                            Home
                        </div>
                    </NavLink>
                    <NavLink to='/quem-somos' exact>
                        <div className="menu-button">
                            Quem somos
                        </div>
                    </NavLink>
                    <NavLink to='/projetos'>
                        <div className="menu-button">
                            Projetos
                        </div>
                    </NavLink>
                    <NavLink to='/contato' exact>
                        <div className="menu-button">
                            Contato
                        </div>
                    </NavLink>
                </div>
                <div className="col-12 col-md-3 col-xl-3 logo-container">
                    <Link to='/' >
                        <img className="logo" src={logo} alt="logo" />
                    </Link>
                    <img className="detail" src={detail} alt="detail" />
                    <div className="button" onClick={() => setMenuMobileOpen(!menuMobileOpen)}>
                        <i className="fas fa-bars"></i>
                    </div>
                </div>
            </div>
            <div className={menuMobileOpen ? 'row mobile-row active' : 'row mobile-row'} >
                {menuMobileOpen === true &&
                    <div className="col-12 menu-mobile">
                        <Link to='/' onClick={() => setMenuMobileOpen(false)} >
                            <div className="menu-button">
                                Home
                            </div>
                        </Link>
                        <Link to='/quem-somos' onClick={() => setMenuMobileOpen(false)}>
                            <div className="menu-button">
                                Quem somos
                            </div>
                        </Link>
                        <Link to='/projetos' onClick={() => setMenuMobileOpen(false)}>
                            <div className="menu-button">
                                Projetos
                            </div>
                        </Link>
                        <Link to='/contato' onClick={() => setMenuMobileOpen(false)}>
                            <div className="menu-button">
                                Contato
                            </div>
                        </Link>

                        <div className="social-buttons">

                            <div className="social-wrapper">
                                <div className="button">
                                    <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                </div>
                                <div className="button">
                                    <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                                        <i className="fab fa-pinterest"></i>
                                    </a>
                                </div>
                                <div className="button">
                                    <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                }
            </div>
        </Container>
    )
}
