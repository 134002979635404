import React from 'react';

import { CardContainer } from './style'
import { Link } from 'react-router-dom';

export default function galeria(props) {
    return (
        <CardContainer className="col-12 col-md-6 col-lg-4 card-container">
            <Link to={`projetos/${props.link}`}>
                <img src={props.imagens[0]} alt={props.titulo} />
                <div className="mask">
                    <div className="titulo">
                        {props.titulo}
                    </div>
                    <div className="categoria">
                        - {props.categoria} -
                    </div>
                </div>
            </Link>
        </CardContainer>
    );
}
