import styled from "styled-components";

export const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    background: #00000050;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;

    .inner-container {
        background-color: #fff;
        font-size: 2rem;
        padding: 30px;
        border-radius: 30px;
    }
`