import React from 'react'


import Topo from '../components/tituloTopo/topo'
import ProjetoCard from '../components/projetoCard/projetoCard'
import Galeria from '../components/galeria/galeria'

export default function ProjetosInterna(props) {
    return (
        <div>
            <Topo />
            <ProjetoCard {...props} />
            <Galeria />
        </div>
    )
}
